export const internalPaths = {
    accountsPath: 'user/accounts',
    adminsPath: 'admins',
    azureSecretsPath: 'azure/secrets',
    chatPath: 'chat',
    iterablePath: 'crm/iterable',
    progressPath: 'user/progress',
    rescheduledStepPath: 'user/rescheduled-step',
    stepProgressPath: 'user/step-progress',
    subscriptionsPath: 'user/subscriptions',
    geoLocationPath: 'user/geolocation',
    agentsPath: 'chat/agents',
    chatTagsPath: 'chat/tags',
    foodCalculatorPath: 'food-calculator',
    pinnedTopicsPath: 'user/pinned-topics',
    courseProgressPath: 'user/course-progress',
    saUserPath: 'separation-anxiety/user',
    feedbackContentPath: 'user/feedback',
    householdPath: 'households',
    dogPath: 'households/dogs',
    householdUserPath: 'households/users',
    uuidPath: 'uuid',
};
