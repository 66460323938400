<div class="square-card">
    @if (!unlocked()) {
        @if (isBasicPlan()) {
            <app-generic-chip
                class="square-card__premium-chip"
                text="Premium"
                icon="fa-regular fa-crown"
                backgroundColor="sophie"
                textColor="snowy"
                padding="4px 8px"
            ></app-generic-chip>
        } @else {
            <i class="fa-solid fa-lock square-card__locked-icon" data-test="square-card__locked-icon"></i>
        }
    }
    <ion-card class="{{ background() }} square-card__outer-container">
        <div class="square-card__container {{ !unlocked() ? 'square-card__container--locked' : '' }}">
            <img class="square-card__img" [src]="imageUrl()" />
            <h2 class="square-card__title" [ngStyle]="{ color: 'var(--ion-color-' + background() + '-contrast)' }">
                {{ title() }}
            </h2>
            <p class="square-card__description" [ngStyle]="{ color: 'var(--ion-color-' + background() + '-contrast)' }">
                {{ description() }}
            </p>
        </div>
    </ion-card>
</div>
